
          $base-color: #406C10;
          $base-color-light: #EBFFD5;
          $base-background-color: #F2F2F2;
        
.rjsf {
    .field-object {
        padding: 0 !important;
    }
    #root {
        .form-group {
            margin-bottom: 1rem;
        }
        .required {
            color: red;
            margin-left: .25rem;
        }
        input[type="checkbox"] {
            margin-right: .5rem;
        }
    }
}