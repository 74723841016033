
          $base-color: #406C10;
          $base-color-light: #EBFFD5;
          $base-background-color: #F2F2F2;
        
// Theme variables
@import "/src/assets/scss/1-variables/app";

.inspections-container {
  .nav-item {
    background-color: $gray-light;
    border: 1px solid transparent;
    &.is-dragging {
      border: 1px dashed white;
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.06);
    }
    &:has(.active) {
      background-color: white;
      border: 1px solid $gray-light;
    }
    .draggable-icon {
      cursor: grab;
      color: #949494;
    }

    .nav-link {
      color: #757575;
      &.active,
      &.show,
      &:hover,
      &:focus {
        background: transparent;
        border: none;
        outline: none;
      }
    }
  }

  .remove-btn {
    &:hover {
      cursor: pointer;
      border: 0;
      background: #f4f4f4;
    }
  }

  .add-btn-container {
    display: grid;
    margin-left: 24px;
  }

  .btn-dashed {
    border: 1px dashed #949494;
    padding: 12px;
    font-size: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    color: #949494;
    &:hover {
      color: $primary !important;
      svg {
        color: $primary;
      }
    }
  }

  .quick-filter {
    &__button {
      border: 0;
      font-size: 15px;
      padding: 2px 16px;
      &:nth-child(1) {
        background-color: black;
        color: white;
      }
      &:nth-child(2) {
        background-color: #1bb37333;
        color: #1bb373;
      }
      &:nth-child(3) {
        background-color: #eef1d9;
        color: #5e6a09;
      }
    }
  }
}

.sortable-wrapper {
  background-color: transparent;
  &.is-dragging {
    .sortable-item {
      box-shadow: 0px -2px 16px 0px rgba(0, 0, 0, 0.06);
    }
  }
}

.group-container,
.section-container {
  transition: border-color 0.5s ease;
}
